import "./normalize.css";
import "./style.css";
import FeaturedImage from "./img/illustrations-8.jpg";
import CloseIcon from "./close.svg"

const main = document.querySelector(".main");

const importAll = (require) =>
  require.keys().reduce((acc, next) => {
    acc[next.replace("./", "")] = require(next);
    return acc;
  }, {});

const Images = importAll(require.context("./img", false, /\.(png|jpe?g|svg)$/));


const pages = {
  home: {
    name: "Home",
  },
  illustrations: {
    name: "Illustrations",
  },
  paintings: {
    name: "Paintings",
  },
  digital: {
    name: "Digital",
  },
  comics: {
    name: "Comics",
  },
  oilPastel: {
    name: "Oil Pastels",
  },
};

window.current = "home";

const buildSidebar = () => {
  let sidebar = document.createElement("section");
  sidebar.classList.add("sidebar");
  for (let [name, val] of Object.entries(pages)) {
    let a = document.createElement("a");
    a.href = `#${name}`;
    a.dataset.id = name;
    a.textContent = val.name;
    if (window.current == name) {
      a.classList.add("selected");
    }
    sidebar.append(a);
  }
  return sidebar;
};

const buildHome = () => {
  let featuredImage = document.createElement("section");
  featuredImage.classList.add("featured-image");

  let img = new Image();
  img.src = FeaturedImage;

  featuredImage.append(img);

  return featuredImage;
};

const showShadowBox = (image) => { 
    let img = new Image()
    img.src = image

    let shadowBox = document.querySelector(".shadowBox")
    shadowBox.classList.add("active")

    let shadowBoxExit = document.querySelector(".shadowBoxExit")
    shadowBoxExit.innerHTML = CloseIcon

    let shadowBoxImage = document.querySelector(".shadowBoxImage")
    shadowBoxImage.appendChild(img)
}

const hideShadowBox = () => {
    let shadowBoxImage = document.querySelector(".shadowBoxImage > img")
    shadowBoxImage.remove()
    let shadowBox = document.querySelector(".shadowBox")
    shadowBox.classList.remove("active")
}

const buildPortfolio = (view) => {
  let images = document.createElement("section");
  images.classList.add("images");

  let imageCount = pages[view].images;
  
  Object.keys(Images).filter(e => e.split("-")[0] == view).forEach(e => {
    let img = new Image();   
    img.src = Images[e].default;
    img.addEventListener("click", e=> {
        showShadowBox(img.src)
    })

    images.append(img);
  })

    
  return images;
};

const app = (async) => {
  main.append(buildSidebar());

  show(false)
};

const show = (f) => {
    let hash = window.location.hash.replace("#", "");
    window.current = hash;


    if(window.current.length < 1) {
        window.current = "home"
    }

    if(f) {
        document.querySelector(".selected").classList.remove("selected");
        window.mainView.remove();

    }
    

    document.querySelector(`[data-id="${window.current}"]`).classList.add("selected");

    if (window.current === "home") {
      window.mainView = buildHome();
    } else {
      window.mainView = buildPortfolio(window.current);
    }

    main.append(window.mainView);
}

window.addEventListener("load", app);
window.addEventListener(
  "hashchange",
  (e) => {
    show(true)
  },
  false
);
document.querySelector(".shadowBoxExit").addEventListener("click", e=>hideShadowBox())
window.addEventListener("keydown", event => {
    if(event.code == "Escape") {
        hideShadowBox()
    }
})